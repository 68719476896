/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import styled from '@emotion/styled';
import notify from 'notify';

import { getListingAddress, getListingImageProps } from 'utils/listing';
import { tryGetFirstError } from 'utils/requests';
import { Button, Input, Dropdown, InputAutocomplete, Icon, Image, Checkbox, InputPlaces, InputCurrency } from 'components';
import { RadioButton, DropdownOption } from '@xchange/uikit';
import { AutocompleteOption } from 'components/InputAutocomplete';
import { REGEXP, STATES } from 'consts';
import listingTypes from 'utils/listingTypes.json';
import { searchListingsByAddressOrMlsId, getTeam } from './api';
import BuyProcessHint from './BuyProcessHint';
import { ReactComponent as HouseCheckmarkSVG } from './houseCheckmark.svg';

const STEP = 1;

const statesOptions = Object.entries(STATES).map(([value, text]) => ({ value, text }));

const listingTypeOptions: DropdownOption[] = Object.values(listingTypes).map(value => ({
  value,
  text: value
}));

const MlsSearchItem = ({ title, text, term }) => {
  const html = title.replace(term, `<strong>${term}</strong>`);
  return (
    <StyledSearchItem>
      <div className="title">
        <span className="number">#</span>
        <span dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <div className="address">{text}</div>
    </StyledSearchItem>
  );
};

const AddressSearchItem = ({ title, text, term }) => {
  const html = title.replace(term, `<strong>${term}</strong>`);
  return (
    <StyledSearchItem>
      <div className="title">
        <Icon name="location" /> <span dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <div className="address-search">{text}</div>
    </StyledSearchItem>
  );
};

interface BuyProcessFormAddressProps {
  listing?: Listing;
  isEditing: boolean;
  clickedOnNewOffer?: boolean;
  notListed?: boolean;
  openFromWorkspace?: boolean;
  onStartEdit: (step: number) => void;
  onSubmit: (userOffer: String,listing: Listing, offerYesOrNo: string, propertyNotListedOnMls: boolean) => void;
}



const BuyProcessFormAddress: React.FC<BuyProcessFormAddressProps> = ({
  listing: listingInit,
  isEditing,
  clickedOnNewOffer,
  notListed,
  openFromWorkspace,
  onStartEdit,
  onSubmit
}) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: listingInit
      ? {
        address: getListingAddress(listingInit),
        address1: listingInit.address1,
        address2: listingInit.address2,
        city: listingInit.city,
        state: listingInit.state,
        zip: listingInit.zip,
        listingPrice: listingInit.listingPrice || "",
        propertyType: listingInit.propertyType || "",
        pin: listingInit.pin || "",
        userForOffer: ""
      }
      : undefined
  });

  const [listing, setListing] = useState<Listing | undefined>(listingInit);
  const [listings, setListings] = useState<Listing[]>([]);
  const [submitOffer, setSubmitOffer] = useState("offerNo");
  const [propertyNotListedOnMls, setPropertyNotListedOnMls] = useState(Boolean(false));
  const [selectedPropertyNotListedOnMls, setSelectedPropertyNotListedOnMls] = useState(Boolean(false));
  const [selectedGoogleAddress, setSelectedGoogleAddress] = useState(Boolean(false));
  const [location, setLocation] = useState(null);
  const [listingAddressNotListed, setListingAddressNotListed] = useState({});
  const [offerUsers, setOfferUsers] = useState<DropdownOption[]>([]);
  const [selectedOfferUser, setSelectedOfferUser] = useState("");
  const [listingAddressNotListedString, setListingAddressNotListedString] = useState("");
  const abortController = useRef<AbortController>(null);

  useEffect(() => {
    setPropertyNotListedOnMls(Boolean(notListed));
  }, [notListed]);

  useEffect(() => {
    setListingAddressNotListedString(getListingAddress(listingAddressNotListed));
  }, [listingAddressNotListed]);

  useEffect(() => {
    setSelectedGoogleAddress(false);
  }, [false]);

  const handleChangeMainUser = (newUser: string) => {
    setSelectedOfferUser(newUser);
  };

  const fetchListingsOptions = async (search: string) => {
    abortController.current?.abort();
    (abortController.current as any) = new AbortController();
    const searchString = search.toLowerCase();
    try {
      const listings = await searchListingsByAddressOrMlsId(
        searchString,
        abortController.current?.signal
      );
      setListings(listings);
      const mlsIds: Listing[] = [];
      const addresses: Listing[] = [];
      listings.forEach(listing => {
        if (listing.id.toLowerCase().includes(searchString)) {
          return mlsIds.push(listing);
        }
        if (listing?.addressSearch?.toLowerCase()?.includes(searchString)) {
          addresses.push(listing);
        }
      });
      const options: AutocompleteOption[] = [
        { text: 'Addresses:', disabled: true },
        ...addresses.map(item => ({
          id: item.id,
          text: (
            <AddressSearchItem title={item.address1} text={getListingAddress(item)} term={search} />
          )
        })),
        { text: 'MLS ID:', disabled: true },
        ...mlsIds.map(item => ({
          id: item.id,
          text: <MlsSearchItem title={item.id} text={getListingAddress(item)} term={search} />
        }))
      ];

      return options;
    } catch (err) {
      if (err.name !== 'AbortError') {
        notify(tryGetFirstError(err));
      }
    }
  };

  const submit = async (data) => {    
    if(propertyNotListedOnMls){
      setListing(undefined);
      const submitvalues = {
        notListed: 1,
        address: data.address,
        address1: data.address1,
        address2: data.address2,
        locality: data.city,
        county: location?.address_components?.find(item => item.types.includes('administrative_area_level_2'))?.long_name || data.county,
        region: data.state,
        zip: data.zip,
        property_type: data.propertyType,
        listing_price: data.listingPrice,
        pin: data.pin
      }
      setListingAddressNotListed(submitvalues);
      onSubmit(selectedOfferUser,submitvalues, submitOffer, propertyNotListedOnMls);
    }

    if (listing){
      onSubmit(selectedOfferUser,listing, submitOffer, propertyNotListedOnMls);
    } 
  };

  const optionalStringWithSpace = (str?: string) => (str ? `${str} ` : '');

  const handleOptionSelect = listingId => {
    const listing = listings?.find(listing => listing.id === listingId);
    if (!listing) return;

    const { address1, address2, city, state, zip } = listing;
    reset({
      address: getListingAddress(listing),
      address1,
      address2,
      city,
      state,
      zip
    });
    setListing(listing);
    getTeamMembers();
  };

  const handleAddressChange = place => {
    if (!place) return;
    setLocation(place);

    const streetNumber = place.address_components?.find(item => item.types.includes('street_number'))?.long_name;
    const route = place.address_components?.find(item => item.types.includes('route'))?.long_name;

    setValue('address1', `${optionalStringWithSpace(streetNumber)}${route}`);
    setValue('city', place.address_components?.find(item => item.types.includes('locality'))?.long_name);
    setValue('state', place.address_components?.find(item => item.types.includes('administrative_area_level_1'))?.short_name);
    setValue('zip', place.address_components?.find(item => item.types.includes('postal_code'))?.short_name);
    setSelectedGoogleAddress(true)
    setSelectedPropertyNotListedOnMls(true);
    
  };

  const getTeamMembers = async () => { 
    const teamMembers = await getTeam();

    const usersDropdown : DropdownOption[] = Object.values(teamMembers["team"]).map(row => ({
      value: row.user_id.toString(),
      text: row.name + (row.is_teamlead == 1 ? " (Team Lead)" : "")
    }));
    
    setOfferUsers(usersDropdown);
    setSelectedOfferUser(teamMembers["me"].toString());
  }

  if (!isEditing && (listing || selectedPropertyNotListedOnMls)) {
    return (
      <div className="step-info">
        {listing && (
          <Image
            size="54x54"
            className="listing-photo"
            {...getListingImageProps(listing!.images?.[0])}
          />
        )}
        {selectedPropertyNotListedOnMls && (
          <Image
            size="54x54"
            className="listing-photo"
          />
        )}
        <div>
          {listing && (<h3 className="step-info__primary">{getListingAddress(listing)}</h3>)}
          {selectedPropertyNotListedOnMls && listingAddressNotListedString && (
            <h3 className="step-info__primary">
              {listingAddressNotListedString}
            </h3>
          )}
          <p className="step-info__secondary">MLS ID: {!propertyNotListedOnMls ? listing!.listingId.replace(/\D/g, "") : "N/A"}</p>
        </div>
        {!openFromWorkspace && (<Button className="step-info__edit-btn" simple onClick={() => onStartEdit(STEP)}>
          Edit
        </Button>
        )}
      </div>
    );
  }

  return (
    <StyledForm onSubmit={handleSubmit(submit)}>
      <BuyProcessHint>
        <HouseCheckmarkSVG /> Please enter the MLS ID or the address of the property
      </BuyProcessHint>
      <div className="input-group">
        {!(propertyNotListedOnMls) && (
          <>
            <Controller
              control={control}
              name="address"
              rules={{ required: 'Required' }}
              defaultValue=""
              render={({ field, formState: { errors } }) => (
                <InputAutocomplete
                  error={errors.address?.message}
                  label="MLS ID or Address*"
                  placeholder="Enter MLS ID or Address"
                  minLength={2}
                  showSpinner
                  getOptions={fetchListingsOptions}
                  onOptionSelect={option => handleOptionSelect(option.id)}
                  style={{ gridColumn: 'span 4' }}
                  {...field}
                />
              )}
            />
          </>
        )}
        {(propertyNotListedOnMls) && (
          <>
            <Controller
              control={control}
              name="address"
              defaultValue=""
              rules={{
                required: 'Required',
                maxLength: {
                  value: 100,
                  message: `Address can not exceed 100 characters`
                },
                pattern: {
                  value: REGEXP.ADDRESS,
                  message: 'Invalid Address'
                }
              }}
              render={({ field: { onChange, ...field } }) => (
                <InputPlaces
                  label={
                    <>
                      Enter address*
                    </>
                  }
                  placeholder="Enter address"
                  error={errors.address?.message}
                  autoComplete="off"
                  onChange={onChange}
                  onPlaceChange={(query, place) => {
                    handleAddressChange(place);
                    onChange(query);

                  }}
                  {...field}
                />
              )}
            />
          </>
        )}
      </div>
      <div className="input-group">
        <Checkbox
          checked={propertyNotListedOnMls}
          onChange={e =>  { setPropertyNotListedOnMls(e.target.checked);
                            setListing(undefined);
                            setSelectedPropertyNotListedOnMls(false);
                            setValue('address', "");   
          }}
          label="Property NOT listed on MLS"
          disabled={false}
        />
      </div>
      {(selectedPropertyNotListedOnMls)  && (
        <>
          <div className="input-group">
            <Input
              {...register('address1', {
                required: 'Required'
              })}
              name="address1"
              error={errors.address1?.message}
              label="Address line 1*"
              placeholder="Enter Address line 1"
            />
            <Input
              {...register('address2')}
              name="address2"
              error={errors.address2?.message}
              label="Address line 2"
              placeholder="Enter Address line 2"
            />
          </div>
          <div className="input-group">
            <Input
              {...register('city', {
                required: 'Required'
              })}
              name="city"
              error={errors.city?.message}
              label="City*"
              placeholder="Enter City"
            />
            <Controller
              control={control}
              rules={{ required: 'Required' }}
              name="state"
              defaultValue=""
              render={({ field, formState: { errors } }) => (
                <Dropdown
                  options={statesOptions}
                  label="State*"
                  placeholder="Select State"
                  error={errors.state?.message}
                  style={{ gridColumn: 'span 1' }}
                  {...field}
                />
              )}
            />
            <Input
              {...register('zip', {
                required: 'Required',
                pattern: { value: REGEXP.ZIP_CODE, message: 'Invalid ZIP Code' },
                maxLength: { value: 5, message: 'Invalid ZIP Code' }
              })}
              error={errors.zip?.message}
              label="Zip Code*"
              placeholder="Enter Zip Code"
              className="input-zip"
              style={{ gridColumn: 'span 1' }}
            />
            {(propertyNotListedOnMls)  && (
              <>
                <Controller
                  control={control}
                  name="listingPrice"
                  defaultValue=""
                  render={({ field }) => (
                    <InputCurrency 
                      label="Listing Price ($)" 
                      placeholder="Enter" 
                      error={errors.listingPrice?.message} 
                      {...field} />
                  )}
                />
                <Controller
                  control={control}
                  rules={{ required: 'Required' }}
                  name="propertyType"
                  defaultValue=""
                  render={({ field }) => (
                    <Dropdown
                      options={listingTypeOptions}
                      label="Property Type*"
                      placeholder="Select"
                      error={errors.propertyType?.message} 
                      search
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="pin"
                  rules={{
                    validate: value => !String(value).includes('_') || 'Invalid PIN'
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      as={MaskedInput}
                      mask="99-99-999-999-9999"
                      error={errors.pin?.message}
                      label="PIN"
                      placeholder="Enter PIN"
                      style={{ gridColumn: 'span 1' }}
                      {...field}
                    />
                  )}
                />
              </>
            )}
          </div>
        </>
      )}
      {(selectedOfferUser)  && (
        <>
          {clickedOnNewOffer && (<hr />)}
          {clickedOnNewOffer && (
            <Controller
              control={control}
              rules={{ required: 'Required' }}
              name="userForOffer"
              defaultValue={selectedOfferUser}
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  options={offerUsers}
                  onChange={value => {
                    handleChangeMainUser(value);
                    onChange(value);
                  }}
                  label="Assign to User*"
                  placeholder="Select"
                  error={errors.userForOffer?.message} 
                  search
                  {...field}
                />
              )}
            />
          )}
        </>
      )}
      {(listing || selectedPropertyNotListedOnMls)  && (
        <>
          {clickedOnNewOffer && (<hr />)}
          {clickedOnNewOffer && (
            <div className="input-group" style={{display: "none"}}>
              <div className="buyprocess-form__radio-group">
                <div className="label">Do you want to create an offer?</div>
                <RadioButton 
                  {...register('offerYesOrNo')} 
                  value="offerYes" 
                  label="Yes" 
                  onClick={() => setSubmitOffer('offerYes')}
                  
                />
                <RadioButton
                  {...register('offerYesOrNo')}
                  value="offerNo"
                  label="No"
                  onClick={() => setSubmitOffer('offerNo')}
                  defaultChecked={true}
                />
              </div>
            </div>
          )}
        </>
      )}
      <div className="buttons-bottom-container">
        {submitOffer === "offerYes" && (
          <Button className="submit-button" 
            disabled={propertyNotListedOnMls  && !selectedGoogleAddress} >
              
            Next
          </Button>
        )}
        {submitOffer === "offerNo" && (
          <Button className="submit-button">
            Submit
          </Button>
        )}
      </div>
    </StyledForm>
  );
};

export default BuyProcessFormAddress;

const StyledForm = styled.form`
  .listing-lookup-info {
    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    p {
      margin: 8px 0;
    }

    .thumbnail {
      float: left;
      margin-right: 30px;
    }

    &__address {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-top: 20px !important;
    }

    &__price {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    &__price-label {
      font-size: 16px;
      line-height: 24px;
      color: ${props => props.theme.colors.grayDark};
    }
  }

  hr {
    border-style: dashed;
    border-color: #c4c4c4;
    margin: 20px 0;
  }

  .input-group {
    margin-bottom: 14px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 14px;

    .input {
      grid-column: span 2;
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      grid-template-columns: 1fr;
    }
  }

  .input-autocomplete {
    &__options-list {
      max-height: 216px;
      overflow: auto;
      max-width: 483px;
    }
  }

  .input-city {
    grid-column: 1 / span 2;
  }

  .dropdown {
    width: 100%;
  }

  .buyprocess-form__radio-group {
    margin: 0 0 12px 0;
    width: 100%;
  }

  .buyprocess-form__radio-group {
    & > .label {
      font-size: 10px;
      margin-bottom: 12px;
    }
    .radiobutton {
      margin-right: 20px;
    }
  }

  .buttons-bottom-container {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;

    .button {
      height: 36px;

      &.submit-button {
        margin-left: 20px !important;
      }
    }
  }
`;

const StyledSearchItem = styled.div`
  .title {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
  }
  .number {
    width: 16px;
  }
  .address,
  .address-search {
    padding-left: 16px;
    font-size: 10px;
    line-height: 19px;
    color: #8892a0;
  }
  .address-search {
    padding-left: 20px;
  }
  .icon {
    margin-right: 4px;
    fill: #000;
  }
`;
